import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import './AboutUsStyle.css';
import BrandsSlider from '../home/BrandsSlider';
import images from '../../resources/images';
import { Helmet } from 'react-helmet';
import { NavLink } from "react-router-dom";

const AboutUsContainer = () => {
  const [click, setClick] = useState(false);
  const handleNavLinkClick = () => {
    if (click) {
      setClick(false);
    }
    window.scrollTo(0, 0);
  };

  const teamMembers = [
    {
      image: images.Arjun,
      name: "Arjun Sindhia",
      description: "Founder & CEO",
    },
    {
      image: images.Sridevi,
      name: "Sridevi",
      description: "Cyber Security Expert",
    },
    {
      image: images.Ranjitha,
      name: "Ranjitha",
      description: "Cyber Security Expert",
    },
    // {
    //   image: images.Rakshith,
    //   name: "Rakshith Manandi",
    //   description: "UI UX Designer",
    // },
    {
      image: images.Shyam,
      name: "Shyam Charan",
      description: "Graphic Designer",
    },
    {
      image: images.Ashok,
      name: "Ashok Reddy",
      description: "Digital Marketing Expert",
    },
    {
      image: images.Tushara,
      name: "Thushara S",
      description: "Front-End Developer",
    },
    {
      image: images.Raghavendra,
      name: "Raghavendra",
      description: "Backend Developer",
    },
    {
      image: images.Dayesh,
      name: "Dayesh",
      description: "Backend Developer",
    },

  ];

  return (
    <React.Fragment>
      <Helmet>
        <title>Our Story | Branding | Web development | Customer Retention</title>
        <meta name="description" content="Start your online success. SEO, branding, web development, and other topics. Move your company forward. Get in touch with us right away." />
        <meta
          name="keywords"
          content="best branding services, webdevelopment agencies, best customer retention services, geekonomy,best branding agency in bangalore,seo, sem best website optimisation services, web developers"
        />
      </Helmet>
      <div className="about-us-container" style={{ backgroundImage: `url(${images.about_us_banner})`, backgroundSize: 'cover' }}>
        <div className='ms-5 serviceAgencies '>
          <h2 className='serviceAgency'>A 360° Service Agency.</h2>

        </div>
      </div>
      <div className='d-flex flex-row ms-md-5 mt-5 justify-content-center justify-content-md-start  aboutUsContentScetion'>
        <div className='ml-3 ms-md-5 text-center text-md-start rightContent'>
          <div className='missionContent'>
            <h1 className='weAre'>We are A to Z.</h1>
            <p className='missionText '>
              As seasoned branding, marketing and development experts, we provide a comprehensive suite of services tailored for your brand's success. From strategic product promotion to cultivating an enduring brand image, we are dedicated to delivering excellence at every turn. Rest assured, we will be your steadfast partner, ensuring your customers' needs are not just met but exceeded, just as we exceed your expectations.
            </p>
          </div>
          <div className='missionContent'>
            <h1 className='weAre'>Our Vision</h1>
            <p className='missionText'>Geekonomy's vision is to be a customer-centric 360° solution provider, leading through advanced technologies and innovation across all service verticals.</p>
          </div>
          <div className='missionContent'>
            <h1 className='weAre'>Our Mission</h1>
            <p className='missionText'>Geekonomy's vision is to be a customer-centric 360° solution provider, leading through advanced technologies and innovation across all service verticals.</p>
          </div>
        </div>
        <div className='d-flex flex-column align-items-end'>
          <img src={images.about_us_circle} alt='earth' className='aboutUsCricle' />
        </div>
      </div>
      <div className='brandsSliderScetion'>
        <BrandsSlider />
      </div>
      <div className='mt-5'>
        <p className='d-flex flex-column align-items-center teamHeading'>Hello There.</p>
        <Row className="teamContainer mt-5">
          {teamMembers.map((member, index) => (
            <Col key={index} xs={12} md={4} className="text-center">
              <img src={member.image} alt={member.name} className='techTeamImg' />
              <div className="teamContainer">
                <h2 className='personName'>{member.name}</h2>
                <p className='role'>{member.description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <div className='d-flex flex-column align-items-center getinContainer'>
        <Container>
          <h3 className='getinText getinTexts text-center text-md-start largeScreen d-none d-md-block'>
            Together, let's embark on a journey of creating an unforgettable brand legacy through
            thorough research, design, marketing and code.
          </h3>
        </Container>
        {/* <h3 className='getinText text-center text-md-start largeScreen d-none d-md-block'>
          Together, let's embark on a journey of creating an unforgettable brand legacy through <br />
          <div className='centered text-center'>thorough research, design, marketing and code.</div>
        </h3> */}
        <h3 className='getinText  smallScreen d-md-none'>
          Together, let's embark on a journey of creating an unforgettable brand legacy through
          thorough research, design, marketing and code.
        </h3>
        <div className='mt-5 aboutUsButton'>

          <Button className='aboutUsBtn' variant="light">
            <NavLink
              className='getintouch'
              to="/ContactUs"
              onClick={() => { handleNavLinkClick(); }}
              style={{ textDecoration: 'none', color: 'black' }} // Custom styles
            >
              Get In Touch
            </NavLink>
          </Button>


        </div>
      </div>
    </React.Fragment>
  )
}
export default AboutUsContainer;